import { Component, OnInit, Input } from '@angular/core';
import { ModalController, PopoverController } from '@ionic/angular';
import { formatDate } from '@angular/common';
import { OHNElement, OHNCalendarEvent, OHNUser} from '../../../models/ohn-instances';
import { OhnApiService } from '../../../services/ohn-api.service';
import { PopoverComponent } from '../../../components/basic/popover/popover.component';
import { API_URL, DEBUG_MODE } from '../../../../environments/environment';

@Component({
  selector: 'app-ctl-start',
  templateUrl: './ctl-start.component.html',
  styleUrls: ['./ctl-start.component.scss'],
})
export class CtlStartComponent implements OnInit {

  @Input() user: OHNUser;

  API_URL = API_URL;

  constructor(
    private ohnApi: OhnApiService,  
    public modalController: ModalController,
    public popoverController: PopoverController
  ) { } 

  ngOnInit() {}

  async openPrint(ev: any) {

    let pdfList = [];


    let printout = {
      category: "CTL Printout",
      links: []
    }

    this.user.histories.ctl_printout.forEach( pdf => {
      let linkItem = {
        link: API_URL + '/reports/' + pdf.value[0],
        text: formatDate(pdf.timestamp, 'MM/dd/yyyy h:mm a', 'en')
      }
      printout['links'].push(linkItem);
    });

    pdfList.push(printout);


    const popover = await this.popoverController.create({
      component: PopoverComponent,
      event: ev,
      componentProps: {
        dataType: 'print',
        inputData: pdfList
      },
      translucent: true
    });
    await popover.present();
  }

}
