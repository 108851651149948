// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false
};

export const API_URL = 'https://ctl-api.ocn.io';
export const APP_SLUG = 'patientspherex';
export const WEB_APP_TITLE = 'LungCARE';

export const BASIC_HEADERS = {
	'Content-Type':  'application/json'
}

export const APP_FOOTNOTE = '';

export const APP_OVERVIEW = 'Welcome to CONNECTing to LungCARE';

export const ENABLE_NOTIFICATION_RULES = false;

export const ENABLE_EXTERNAL_DEVICES_INTEGRATION = false;

export const NOT_AUTHORIZED_MESSAGE = 'You are not authorized to use this app.';

export const APP_AVAILABLE_FOR_ROLES = ['admin', 'coordinator', 'practitioner', 'patient', 'user'];

export const DEFAULT_FOR_ROLES = [];

export const DEBUG_MODE = false;

export const MULTIPLE_PROJECTS_ENABLED = false;

export const SETTINGS_IS_NOT_A_TAB_PAGE = false;

export const HIDE_TABS = true;

export const ENABLE_HEALTH_KIT_DEBUG = false;

export const ENABLE_LINK_BOOKMARKS = false;

export const MULTILINGUAL_APP = false;

export const IS_RAD_ONC_APP = false;

export const INSTACART_ENABLED = false;

export const PATIENT_ASSIGNMENT_ENABLED = false;

export const IS_CTL_APP = true;

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.