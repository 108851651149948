import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { SafeResourceUrl, DomSanitizer } from '@angular/platform-browser';
import { OHNElement } from '../../../models/ohn-instances';
import { OhnLogicService } from '../../../services/ohn-logic.service';
import { ToastController } from '@ionic/angular';

declare var MusePlayer: any;

@Component({
  selector: 'app-muse-ai-video',
  templateUrl: './muse-ai-video.component.html',
  styleUrls: ['./muse-ai-video.component.scss'],
})
export class MuseAiVideoComponent implements OnInit {

  @Input() el: OHNElement;
  @Input() viewMode : string;
  museAiVideoPlayer: HTMLVideoElement;

  validityRules : any[];

  @ViewChild('museAiVideoPlayer')
  set mainVideoEl(el: ElementRef) {
    this.museAiVideoPlayer = el.nativeElement;
  }

  videoUrl: SafeResourceUrl;

  constructor(
    private domSanitizer: DomSanitizer,
    private ohnLogicService: OhnLogicService,
    private toastController: ToastController,
  ) { }

  ngOnInit() {
    this.el.value = 'notViewed';
    this.videoUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(this.el.video_url);
    this.validityRules = (this.el.config && this.el.config.validityRules) ? this.el.config.validityRules : [];
    this.el.valid = this.validityRules.length > 0 ? false : true; 

    //if (this.el.config && this.el.config.videoPauseBeforeAutoPlay) {

      //this.autoplayDelay = this.el.config.videoPauseBeforeAutoPlay;
      //this.initWithDelay();

    //} else {
      setTimeout(()=>{
        this.museAiVideoPlayer.pause();
        this.museAiVideoPlayer.src= this.el.video_url;
        this.museAiVideoPlayer.load();
        if (this.el.config && this.el.config.controls){
          this.museAiVideoPlayer.controls = true;
        }
        if (this.el.config && this.el.config.displayControls){
          this.museAiVideoPlayer.controls = true;
          this.museAiVideoPlayer.addEventListener('play', (e)=> {
            setTimeout(()=>{
              this.museAiVideoPlayer.currentTime = 1;
              setTimeout(()=>{
                this.museAiVideoPlayer.currentTime = 0;
              }, 10)
            }, 1000)
          },false);
        } else {
          this.museAiVideoPlayer.play();
        }
        
        this.museAiVideoPlayer.addEventListener('ended', (e)=> {
          if (this.el.config.showCompletionMessage) {
            this.showComplitionToast()
          }
          this.museAiVideoPlayer.pause();
          this.museAiVideoPlayer.currentTime = this.museAiVideoPlayer.duration - 0.5;
          this.el.value = 'viewed'
          this.el.valid = true;  
        },false);
      }, 20);

   // }
  }

  async showComplitionToast() {
    const toast = await this.toastController.create({
      message: 'Click "Next" to continue',
      duration: 1500,
      position : 'middle'
    });
    toast.present();
  }

  ngOnDestroy() {
    this.museAiVideoPlayer.pause();
  }

}
