import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';
import { NgxEchartsModule } from 'ngx-echarts';
import { MarkdownModule, MarkedOptions, MarkedRenderer } from 'ngx-markdown';
import { NgPipesModule } from 'ngx-pipes';

import { LoadingAnimationComponent } from './components/loading-animation/loading-animation.component';
import { CalendarWidgetComponent } from './components/widgets/calendar-widget/calendar-widget.component';
import { HealthTrackingWidgetComponent } from './components/widgets/health-tracking-widget/health-tracking-widget.component';
import { MedicationsWidgetComponent } from './components/widgets/medications-widget/medications-widget.component';
import { MeetingsWidgetComponent } from './components/widgets/meetings-widget/meetings-widget.component';
import { ContentOpenerWidgetComponent } from './components/widgets/content-opener-widget/content-opener-widget.component';
import { HistoryWidgetComponent } from './components/widgets/history-widget/history-widget.component';
import { CommonCalendarWidgetComponent } from './components/widgets/common-calendar-widget/common-calendar-widget.component';
import { PatientSurveysWidgetComponent } from './components/widgets/patient-surveys-widget/patient-surveys-widget.component';
import { SinglePatientSurveyWidgetComponent } from './components/widgets/single-patient-survey-widget/single-patient-survey-widget.component';
import { CommonSinglePatientAssessmentComponent } from './components/widgets/common-single-patient-assessment/common-single-patient-assessment.component';
import { BasicElementsRendererComponent } from './components/basic-elements-renderer/basic-elements-renderer.component';
import { CalendarActionListManagerComponent } from './components/calendar-action-list-manager/calendar-action-list-manager.component';
import { ProfileFieldsViewComponent } from './components/profile-fields-view/profile-fields-view.component';
import { FitbitDevicePairingComponent } from './components/fitbit-device-pairing/fitbit-device-pairing.component';
import { BasicElementsConfigRendererComponent } from './components/basic-elements-config-renderer/basic-elements-config-renderer.component';
import { BasicElementsConfigPaletteComponent } from './components/basic-elements-config-palette/basic-elements-config-palette.component';
import { StatsChartComponent } from './components/stats-chart/stats-chart.component';
import { UserReportRendererComponent } from './components/user-report-renderer/user-report-renderer.component';
import { RangedRulesRendererComponent } from './components/ranged-rules-renderer/ranged-rules-renderer.component';
import { PrescriptionEditorComponent } from './components/prescription-editor/prescription-editor.component';
import { MeetingsEditorComponent } from './components/meetings-editor/meetings-editor.component';
import { BranchedContentRendererComponent } from './components/branched-content-renderer/branched-content-renderer.component';
import { FullScreenMenuComponent } from './components/full-screen-menu/full-screen-menu.component';
import { UserProfileEditorComponent } from './components/user-profile-editor/user-profile-editor.component';
import { NotificationRulesComponent } from './components/notification-rules/notification-rules.component';
import { NotificationRulesModalComponent } from './components/notification-rules-modal/notification-rules-modal.component';
import { O2RingOximeterComponent } from './components/deviceintegrations/o2-ring-oximeter/o2-ring-oximeter.component';
import { ExternalDeviceManagementComponent } from './components/external-device-management/external-device-management.component';
import { UserFilterSettingsComponent } from './components/user-filter-settings/user-filter-settings.component';
import { RuleEditorComponent } from './components/rule-editor/rule-editor.component';
import { LanguageSelectionComponent } from './components/language-selection/language-selection.component';
import { RadoncStartComponent } from './components/userlist-wigets/radonc-start/radonc-start.component';
import { CtlStartComponent } from './components/userlist-wigets/ctl-start/ctl-start.component';
import { HtmlTemplateRendererComponent } from './components/html-template-renderer/html-template-renderer.component';
import { MultiLevelMenuComponent } from './components/multi-level-menu/multi-level-menu.component';
import { DisclaimerComponent } from './components/disclaimer/disclaimer.component';



import { splitChunkPipe } from './pipes/split-chunk.pipe'
import { roleCutAppSlugPipe } from './pipes/role-cut-app-slug.pipe';
import { textReplacementsPipe } from './pipes/text-replacements.pipe';
import { OrderBy } from './pipes/order-by.pipe';
import { FilterPipe } from './pipes/filter.pipe';


//////////////////Basic Components///////////////////
import { StringFieldElementComponent } from './components/basic/string-field-element/string-field-element.component';
import { DateFieldElementComponent } from './components/basic/date-field-element/date-field-element.component';
import { NumericFieldElementComponent } from './components/basic/numeric-field-element/numeric-field-element.component';
import { PickOneRadioElementComponent } from './components/basic/pick-one-radio-element/pick-one-radio-element.component';
import { HeightInputElementComponent } from './components/basic/height-input-element/height-input-element.component';
import { HeartRateZonesElementComponent } from './components/basic/heart-rate-zones-element/heart-rate-zones-element.component';
import { WeightInputElementComponent } from './components/basic/weight-input-element/weight-input-element.component';
import { YesNoInputElementComponent } from './components/basic/yes-no-input-element/yes-no-input-element.component';
import { ListFieldElementComponent } from './components/basic/list-field-element/list-field-element.component';
import { ContentRawElementComponent } from './components/basic/content-raw-element/content-raw-element.component';
import { ImageElementComponent } from './components/basic/image-element/image-element.component';
import { VideoElementComponent } from './components/basic/video-element/video-element.component';
import { AudioElementComponent } from './components/basic/audio-element/audio-element.component';
import { NumericSliderElementComponent } from './components/basic/numeric-slider-element/numeric-slider-element.component';
import { TimerElementComponent } from './components/basic/timer-element/timer-element.component';
import { SpeedSensorElementComponent } from './components/basic/speed-sensor-element/speed-sensor-element.component';
import { NotesElementComponent } from './components/basic/notes-element/notes-element.component';
import { ExternalLinkElementComponent } from './components/basic/external-link-element/external-link-element.component';
import { NoiseLevelElementComponent } from './components/basic/noise-level-element/noise-level-element.component';
import { FitbitHeartRateComponent } from './components/basic/fitbit-heart-rate/fitbit-heart-rate.component';
import { PickManyElementComponent } from './components/basic/pick-many-element/pick-many-element.component';
import { MuseAiVideoComponent } from './components/basic/muse-ai-video/muse-ai-video.component';
import { RandomizationElementComponent } from './components/basic/randomization-element/randomization-element.component';
import { PeriodToDaysSelectorComponent } from './components/basic/period-to-days-selector/period-to-days-selector.component';
import { NumericPickOneComponent } from './components/basic/numeric-pick-one/numeric-pick-one.component';
import { PrintoutReportComponent } from './components/basic/printout-report/printout-report.component';
import { HiddenCounterComponent } from './components/basic/hidden-counter/hidden-counter.component';
import { JiniaTemplateElementComponent } from './components/basic/jinia-template-element/jinia-template-element.component';
import { SessionCounterComponent } from './components/basic/session-counter/session-counter.component';
import { PhoneNumberElementComponent } from './components/basic/phone-number-element/phone-number-element.component';
import { TextMessageElementComponent } from './components/basic/text-message-element/text-message-element.component';
import { HiddenStringComponent } from './components/basic/hidden-string/hidden-string.component';
import { CurrentTimestampPickerComponent } from './components/basic/current-timestamp-picker/current-timestamp-picker.component';
import { InstacartInstanceComponent } from './components/basic/instacart-instance/instacart-instance.component';
import { CtlGroupSelectionComponent } from './components/basic/ctl-group-selection/ctl-group-selection.component';

import { BloodPressureHistoryRendererComponent } from './components/history-renderers/blood-pressure-history-renderer/blood-pressure-history-renderer.component';
import { NumericHistoryRendererComponent } from './components/history-renderers/numeric-history-renderer/numeric-history-renderer.component';
import { FitbitDataSyncComponent } from './components/fitbit-data-sync/fitbit-data-sync.component';
import { RadoncStaffManagementComponent } from './components/radonc-staff-management/radonc-staff-management.component';

//////////////////Basic Components Config///////////////////
import { ContentRawElementConfigComponent } from './components/basicconfiguration/content-raw-element-config/content-raw-element-config.component';
import { ImageElementConfigComponent } from './components/basicconfiguration/image-element-config/image-element-config.component';
import { NumberElementConfigComponent } from './components/basicconfiguration/number-element-config/number-element-config.component';
import { StringElementConfigComponent } from './components/basicconfiguration/string-element-config/string-element-config.component';
import { VideoElementConfigComponent } from './components/basicconfiguration/video-element-config/video-element-config.component';
import { VideoEmbedElementConfigComponent } from './components/basicconfiguration/video-embed-element-config/video-embed-element-config.component';
import { AudioElementConfigComponent } from './components/basicconfiguration/audio-element-config/audio-element-config.component';
import { PickManyElementConfigComponent } from './components/basicconfiguration/pick-many-element-config/pick-many-element-config.component';
import { PickOneElementConfigComponent } from './components/basicconfiguration/pick-one-element-config/pick-one-element-config.component';
import { SelectElementConfigComponent } from './components/basicconfiguration/select-element-config/select-element-config.component';
import { YesNoElementConfigComponent } from './components/basicconfiguration/yes-no-element-config/yes-no-element-config.component';
import { DateInputElementConfigComponent } from './components/basicconfiguration/date-input-element-config/date-input-element-config.component';
import { TimerElementConfigComponent } from './components/basicconfiguration/timer-element-config/timer-element-config.component';
import { SpeedSensorElementConfigComponent } from './components/basicconfiguration/speed-sensor-element-config/speed-sensor-element-config.component';
import { NotesElementConfigComponent } from './components/basicconfiguration/notes-element-config/notes-element-config.component';
import { LinkElementConfigComponent } from './components/basicconfiguration/link-element-config/link-element-config.component';
import { NoiseLevelElementConfigComponent } from './components/basicconfiguration/noise-level-element-config/noise-level-element-config.component';
import { FitbitHeartRateConfigComponent } from './components/basicconfiguration/fitbit-heart-rate-config/fitbit-heart-rate-config.component';
import { NumericSliderConfigComponent } from './components/basicconfiguration/numeric-slider-config/numeric-slider-config.component';
import { JiniaTemplateConfigComponent } from './components/basicconfiguration/jinia-template-config/jinia-template-config.component';

/*export function markedOptionsFactory(): MarkedOptions {
  const renderer = new MarkedRenderer();

    renderer.heading = (text: string, level: number) => {
      const escapedText = text.toLowerCase().replace(/[^\w]+/g, '-');
      return 'ssdfsdf';
    };

  return {
    renderer: renderer,
    gfm: true,
    breaks: false,
    pedantic: false,
    smartLists: true,
    smartypants: false,
  };
}*/

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    IonicModule,
    NgxEchartsModule,
    NgPipesModule,
    /*MarkdownModule.forRoot({
        markedOptions: {
        provide: MarkedOptions,
        useFactory: markedOptionsFactory,
      }
    })*/
    MarkdownModule.forRoot({})
  ],
  providers: [
    MarkdownModule,
    textReplacementsPipe
  ],
  declarations: [
    //Basic Components
    StringFieldElementComponent,
    DateFieldElementComponent,
    HeightInputElementComponent,
    HeartRateZonesElementComponent,
    NumericFieldElementComponent,
    PickOneRadioElementComponent,
    YesNoInputElementComponent,
    WeightInputElementComponent,
    ListFieldElementComponent,
    ContentRawElementComponent,
    ContentRawElementConfigComponent,
    ImageElementConfigComponent,
    NumberElementConfigComponent,
    StringElementConfigComponent,
    VideoElementConfigComponent,
    VideoEmbedElementConfigComponent,
    AudioElementConfigComponent,
    PickManyElementConfigComponent,
    PickOneElementConfigComponent,
    SelectElementConfigComponent,
    YesNoElementConfigComponent,
    DateInputElementConfigComponent,
    ImageElementComponent,
    VideoElementComponent,
    AudioElementComponent,
    NumericSliderElementComponent,
    SpeedSensorElementComponent,
    SpeedSensorElementConfigComponent,
    TimerElementComponent,
    TimerElementConfigComponent,
    NotesElementComponent,
    NotesElementConfigComponent,
    ExternalLinkElementComponent,
    LinkElementConfigComponent,
    NoiseLevelElementComponent,
    NoiseLevelElementConfigComponent,
    BloodPressureHistoryRendererComponent,
    NumericHistoryRendererComponent,
    FitbitHeartRateComponent,
    FitbitHeartRateConfigComponent,
    PickManyElementComponent,
    NumericSliderConfigComponent,
    MuseAiVideoComponent,
    RandomizationElementComponent,
    PeriodToDaysSelectorComponent,
    NumericPickOneComponent,
    PrintoutReportComponent,
    HiddenCounterComponent,
    SessionCounterComponent,
    PhoneNumberElementComponent,
    TextMessageElementComponent,
    HiddenStringComponent,
    CurrentTimestampPickerComponent,
    InstacartInstanceComponent,
    CtlGroupSelectionComponent,
    //Components
    RadoncStartComponent,
    CtlStartComponent,
    LoadingAnimationComponent,
    BasicElementsRendererComponent,
    CalendarActionListManagerComponent,
    ProfileFieldsViewComponent,
    FitbitDevicePairingComponent,
    BasicElementsConfigRendererComponent,
    BasicElementsConfigPaletteComponent,
    StatsChartComponent,
    UserReportRendererComponent,
    RangedRulesRendererComponent,
    PrescriptionEditorComponent,
    MeetingsEditorComponent,
    FullScreenMenuComponent,
    UserProfileEditorComponent,
    NotificationRulesComponent,
    NotificationRulesModalComponent,
    O2RingOximeterComponent,
    ExternalDeviceManagementComponent,
    UserFilterSettingsComponent,
    FitbitDataSyncComponent,
    RuleEditorComponent,
    LanguageSelectionComponent,
    JiniaTemplateConfigComponent,
    JiniaTemplateElementComponent,
    HtmlTemplateRendererComponent,
    BranchedContentRendererComponent,
    RadoncStaffManagementComponent,
    MultiLevelMenuComponent,
    DisclaimerComponent,
    //Widgets
    CalendarWidgetComponent,
    HealthTrackingWidgetComponent,
    MedicationsWidgetComponent,
    MeetingsWidgetComponent,
    ContentOpenerWidgetComponent,
    HistoryWidgetComponent,
    CommonCalendarWidgetComponent,
    PatientSurveysWidgetComponent,
    SinglePatientSurveyWidgetComponent,
    CommonSinglePatientAssessmentComponent,
    //Pipes
    splitChunkPipe,
    roleCutAppSlugPipe,
    OrderBy,
    FilterPipe,
    textReplacementsPipe
  ],
  exports: [
    ContentRawElementConfigComponent,
    ImageElementConfigComponent,
    NumberElementConfigComponent,
    StringElementConfigComponent,
    StringFieldElementComponent,
    DateFieldElementComponent,
    HeightInputElementComponent,
    HeartRateZonesElementComponent,
    NumericFieldElementComponent,
    PickOneRadioElementComponent,
    YesNoInputElementComponent,
    NumericSliderElementComponent,
    WeightInputElementComponent,
    ListFieldElementComponent,
    ContentRawElementComponent,
    LoadingAnimationComponent,
    CalendarWidgetComponent,
    MedicationsWidgetComponent,
    MeetingsWidgetComponent,
    HealthTrackingWidgetComponent,
    ContentOpenerWidgetComponent,
    HistoryWidgetComponent,
    CommonCalendarWidgetComponent,
    PatientSurveysWidgetComponent,
    SinglePatientSurveyWidgetComponent,
    CommonSinglePatientAssessmentComponent,
    splitChunkPipe,
    roleCutAppSlugPipe,
    OrderBy,
    FilterPipe,
    textReplacementsPipe,
    NgPipesModule,
    BasicElementsRendererComponent,
    CalendarActionListManagerComponent,
    ProfileFieldsViewComponent,
    FitbitDevicePairingComponent,
    BasicElementsConfigRendererComponent,
    BasicElementsConfigPaletteComponent,
    ImageElementConfigComponent,
    VideoElementConfigComponent,
    VideoEmbedElementConfigComponent,
    AudioElementConfigComponent,
    PickManyElementConfigComponent,
    PickOneElementConfigComponent,
    SelectElementConfigComponent,
    YesNoElementConfigComponent,
    DateInputElementConfigComponent,
    VideoElementComponent,
    AudioElementComponent,
    ImageElementComponent,
    StatsChartComponent,
    UserReportRendererComponent,
    RangedRulesRendererComponent,
    PrescriptionEditorComponent,
    MeetingsEditorComponent,
    TimerElementComponent,
    TimerElementConfigComponent,
    SpeedSensorElementConfigComponent,
    SpeedSensorElementComponent,
    FullScreenMenuComponent,
    NotesElementConfigComponent,
    NotesElementComponent,
    ExternalLinkElementComponent,
    LinkElementConfigComponent,
    NoiseLevelElementComponent,
    NoiseLevelElementConfigComponent,
    BloodPressureHistoryRendererComponent,
    NumericHistoryRendererComponent,
    FitbitHeartRateConfigComponent,
    FitbitHeartRateComponent,
    UserProfileEditorComponent,
    NotificationRulesComponent,
    NotificationRulesModalComponent,
    O2RingOximeterComponent,
    PickManyElementComponent,
    NumericSliderConfigComponent,
    ExternalDeviceManagementComponent,
    UserFilterSettingsComponent,
    FitbitDataSyncComponent,
    RuleEditorComponent,
    LanguageSelectionComponent,
    MuseAiVideoComponent,
    RandomizationElementComponent,
    RadoncStartComponent,
    CtlStartComponent,
    PeriodToDaysSelectorComponent,
    NumericPickOneComponent,
    PrintoutReportComponent,
    HiddenCounterComponent,
    JiniaTemplateConfigComponent,
    JiniaTemplateElementComponent,
    HtmlTemplateRendererComponent,
    SessionCounterComponent,
    PhoneNumberElementComponent,
    TextMessageElementComponent,
    BranchedContentRendererComponent,
    RadoncStaffManagementComponent,
    HiddenStringComponent,
    CurrentTimestampPickerComponent,
    MultiLevelMenuComponent,
    DisclaimerComponent,
    InstacartInstanceComponent,
    CtlGroupSelectionComponent
  ] 
})

export class SharedModule {}
