import { Component, OnInit, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { OHNElement, OHNUser } from '../../models/ohn-instances';
import { OhnApiService } from '../../services/ohn-api.service';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { API_URL, IS_CTL_APP } from '../../../environments/environment';
import * as _ from 'underscore/underscore';
import { OhnLanguageService } from '../../services/ohn-language.service';

@Component({
  selector: 'app-html-template-renderer',
  templateUrl: './html-template-renderer.component.html',
  styleUrls: ['./html-template-renderer.component.scss'],
})
export class HtmlTemplateRendererComponent implements OnInit {

  @Input() elements: any;
  @Input() reportElementSlug: string;
  @Input() user: OHNUser;
  @Input() el: OHNElement;

  IS_CTL_APP : boolean = IS_CTL_APP;

  type : string = 'intervention';

  date : string;

  reportElements : any = {};

  loading: boolean = true;

  currentSmoker : boolean;

  otherSmokingProducts : string;

  fileURL : string;

  constructor(
    public modalController: ModalController,
    private ohnApi: OhnApiService,
    private iab: InAppBrowser,
    private lS: OhnLanguageService,
  ) { }

  ngOnInit() {
    this.date = new Date().toDateString();
    if (IS_CTL_APP) {
      this.loadReportStateCTL();
    } else {
      this.loadReportState();
    }
  }

  loadReportStateCTL() {
    this.ohnApi.getElementStateSc(this.reportElementSlug, this.user.smart_contract).subscribe(state =>{   
      let keys: string[] = _.keys(state.value.value.element_slug);
      keys.forEach((k)=>{
        if (state.value.value.controller[k] == "pickManyDefaultController" && state.value.value.choice_slugs[k]) {
          let valuesArray : string[] = [];
          state.value.value.choice_slugs[k].forEach((v)=>{
            let value = this.elements[state.value.value.element_slug[k]].elements.find((e)=>{return e.element_slug == v}).text;//this.elements[state.value.value.element_slug[k]].elements[v].text;
            valuesArray.push(value);
          });
          this.reportElements[state.value.value.element_slug[k]] = valuesArray;
        } else {
          this.reportElements[state.value.value.element_slug[k]] = state.value.value.alt_value[k];
        }
      });

      if (!this.elements['randomization-for-groups-91724'].value && !this.elements['intervention-control-randomizati-72877'].value) {
        this.type = 'ctlInteligible'
      }

      if (this.elements['intervention-control-randomizati-72877'].value === 0) {
        this.type = 'ctlControl'
      }

      if (this.elements['intervention-control-randomizati-72877'].value === 1) {
        switch (this.elements['randomization-for-groups-91724'].value) {
          case 0 :
            this.type = 'ctlLCS'
            break;
          case 1 :
            this.type = 'ctlLCSSmoke'
            break;
          case 2 :
            this.type = 'ctlSmoker'
            break;
        }
      }
      
      this.loading = false;
      setTimeout(()=>{this.getPDF()}, 200);
    });
    
  }

  loadReportState() {

    this.ohnApi.getElementStateSc(this.reportElementSlug, this.user.smart_contract).subscribe(state =>{   
      let keys: string[] = _.keys(state.value.value.element_slug);
      keys.forEach((k)=>{
        if (state.value.value.controller[k] == "pickManyDefaultController" && state.value.value.choice_slugs[k]) {
          let valuesArray : string[] = [];
          state.value.value.choice_slugs[k].forEach((v)=>{
            let value = this.elements[state.value.value.element_slug[k]].elements.find((e)=>{return e.element_slug == v}).text;//this.elements[state.value.value.element_slug[k]].elements[v].text;
            valuesArray.push(value);
          });
          this.reportElements[state.value.value.element_slug[k]] = valuesArray;
        } else {
          this.reportElements[state.value.value.element_slug[k]] = state.value.value.alt_value[k];
        }
      });

      if (this.elements['intervention-control-randomizati-15683']) {
        if (this.elements['intervention-control-randomizati-15683'].value == 1) {
          this.type = 'intervention'
        }
        if (this.elements['intervention-control-randomizati-15683'].value != 1) {
          this.type = 'control'
        }
        if ((this.elements['in-the-past-12-months-have-you-13909'].value && this.elements['in-the-past-12-months-have-you-13909'].value.length == 1 && this.elements['in-the-past-12-months-have-you-13909'].value.indexOf('none-of-the-above-78800') >= 0) || (this.elements['already-completed-74350'].value && this.elements['already-completed-74350'].value.length > 0) || (this.elements['consent-unsure-20315'].value && this.elements['consent-unsure-20315'].value != 'ready-to-join-18863')) {
          this.type = 'ineligible'
        }
      }

      if (this.reportElementSlug == "report-for-returning-session-ext-65808") {
        this.type = "followUpIntervention";
        if (this.elements['please-specify-if-other-19937'] && this.elements['please-specify-if-other-19937'].value) {
          this.otherSmokingProducts = this.elements['please-specify-if-other-19937'].value;
        }

        this.currentSmoker = (this.reportElements['in-the-last-30-days-have-you-sm-28386'] == 'Every day') || (this.reportElements['in-the-last-30-days-have-you-sm-28386'] == 'Some days' && this.reportElements['when-did-you-last-smoke-even-a-30879'] < 7);

        if (this.reportElements['session-counter-57950'] == null) {
          this.reportElements['session-counter-57950'] = 2;
        }

      } else {
        if (this.elements['please-specify-if-other-78935'] && this.elements['please-specify-if-other-78935'].value) {
          this.otherSmokingProducts = this.elements['please-specify-if-other-78935'].value;
        }
        this.currentSmoker = (this.reportElements['in-the-last-30-days-have-you-sm-28386'] != 'Not at all');
      }

      
      this.loading = false;
      setTimeout(()=>{this.getPDF()}, 200);
    });
    
  }

  getPDF() { 
    //this.ohnApi.generatePDF('Printout', this.user.username, "<div style=\"padding:24px;font-family: sans-serif;\"><center><img src=\"https://www.openhealth.cc/assets/art/seal.svg\"></center><p>Than you for joining the iMD Study and providing us your current tobacco use status. Here is a summary of the information that your provided, and we included some resources that may be useful to you.</p><p>Name: <strong><(a.3.)></strong><br>Date: <strong><(fill from the system)></strong><br>Week of Radiation Treatment: <strong><(a.4.)></strong></p><h3><u>TOBACCO USE STATUS:</u></h3><ul><li>Currently, you smoke: <strong><(a.6.)></strong></li><li>In the past 12 months you have used the following tobacco products: <strong><(a.5.i)></strong></li><li>Last time smoked or used tobacco: <strong><(FOR SOME DAY AND FORMER SMOKERS, a.7. with unit) (FOR EVERYDAY SMOKER, N/A)></strong></li><li>Number of cigarettes smoked on the day when you smoke: <strong><(FOR CURRENT AND SOMEDAY SMOKERb.1.) (FOR FORMER SMOKER, show N/A)></strong></li><li>First cigarette/ tobacco use: <strong><(b.2.)></strong>  minutes after waking</li></ul></div>").subscribe((pdf: any) => {
    /*this.ohnApi.generatePDF('Printout', this.user.username, document.getElementById('htmlContainer').innerHTML).subscribe((pdf: any) => {
      let file = new Blob([pdf], {type: 'application/pdf'});
      let fileURL = URL.createObjectURL(file);
      const browser = this.iab.create(fileURL, '_system');
    });*/
    if (!localStorage.getItem('ohn-radonc-pdf-recently-generated-state-id')) {
      this.ohnApi.setElementStateSc(this.el.element_slug, {value : document.getElementById('htmlContainer').innerHTML}, this.user.smart_contract).subscribe((pdfLinks: any) => {
        this.fileURL = API_URL + '/reports/' + pdfLinks.value;
        localStorage.setItem('ohn-radonc-pdf-recently-generated-state-id', pdfLinks.id);
       // const browser = this.iab.create(fileURL, '_system');
      });
    } else {
      this.ohnApi.patchElementStateSc(this.el.element_slug, {value : {id : localStorage.getItem('ohn-radonc-pdf-recently-generated-state-id'), value : document.getElementById('htmlContainer').innerHTML}}, this.user.smart_contract).subscribe((pdfLinks: any) => {
        this.fileURL = API_URL + '/reports/' + pdfLinks.value;
       // const browser = this.iab.create(fileURL, '_system');
      });
    }
  }

  closeModal() {
    this.modalController.dismiss();
  }

}
