import { Component, OnInit, Input } from '@angular/core';
import { OHNElement, OHNUser } from '../../../models/ohn-instances';
import { OhnApiService } from '../../../services/ohn-api.service';
import { OhnLogicService } from '../../../services/ohn-logic.service';

@Component({
  selector: 'app-randomization-element',
  templateUrl: './randomization-element.component.html',
  styleUrls: ['./randomization-element.component.scss'],
})
export class RandomizationElementComponent implements OnInit {

  @Input() el: OHNElement;
  @Input() user : OHNUser;

  constructor(
    private ohnApi: OhnApiService,
    private ohnLogicService: OhnLogicService,
  ) { }

  ngOnInit() {
    this.el.valid = false;
    this.el.value = 1;
    this.getRandomizationState();
  }

  getRandomizationState() {
    this.ohnApi.getElementStateSc(this.el.element_slug, this.user.smart_contract).subscribe(state=>{
      if (state.value !== undefined && state.value !== null && state.value.value !== undefined && state.value.value !== null) {
        this.el.value = parseInt(state.value.value);
        this.ohnLogicService.setValueOfElementContainer(this.el.element_slug, this.el.value);
        this.el.valid = true;
        this.radOncTestState();
      } else {
        this.setRandomizationState();
      }
    })
  }

  setRandomizationState() {
    this.ohnApi.setElementStateSc(this.el.element_slug, {value : this.el.config.randomizationValue}, this.user.smart_contract).subscribe(state=>{
      this.el.value = parseInt(state.value);
      this.el.valid = true;
      this.ohnLogicService.setValueOfElementContainer(this.el.element_slug, this.el.value);
      this.radOncTestState();
    })
  }

  radOncTestState() {
    if (localStorage.getItem('ohn-radonc-group')) {
      this.el.value = parseInt(localStorage.getItem('ohn-radonc-group'));
      this.ohnLogicService.setValueOfElementContainer(this.el.element_slug, this.el.value);
      this.el.valid = true;
    }
  }

}
