import { Component, OnInit, Input } from '@angular/core';
import { OhnLogicService } from '../../../services/ohn-logic.service';

@Component({
  selector: 'app-ctl-group-selection',
  templateUrl: './ctl-group-selection.component.html',
  styleUrls: ['./ctl-group-selection.component.scss'],
})
export class CtlGroupSelectionComponent implements OnInit {

  @Input() el: any;

  constructor(
    private ohnLogicService: OhnLogicService,
  ) { 
  }

  ngOnInit() {
    this.el.valid = true

    let a1 : string = this.ohnLogicService.getValueFromElementContainer('in-the-last-30-days-did-you-smo-15651');
    let a2 : string = this.ohnLogicService.getValueFromElementContainer('when-did-you-last-smoke-any-ciga-54736');
    let a3a : number = this.ohnLogicService.getValueFromElementContainer('on-average-how-many-cigarettes-76896');
    let a4a : number = this.ohnLogicService.getValueFromElementContainer('how-many-years-have-you-smoked-52961');
    let a3b : number = this.ohnLogicService.getValueFromElementContainer('on-average-when-you-used-to-smo-67170');
    let a4b : number = this.ohnLogicService.getValueFromElementContainer('how-many-years-did-you-smoke-reg-95160');
    let packYears : number;
    if (a1 != 'not-at-all-60238') {
      packYears =  a3a/20*a4a
      if (packYears >= 20) {
        this.el.value = 1
      } else {
        this.el.value = 2
      }
    } else {
      this.el.value = 0
    }
    this.ohnLogicService.setValueOfElementContainer(this.el.element_slug, this.el.value);
    console.log(this.el.value)
  }
}