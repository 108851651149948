import { Component, OnInit, Input } from '@angular/core';
import { AlertController, ModalController, PopoverController } from '@ionic/angular';
import { formatDate } from '@angular/common';
import { OHNElement, OHNUser, OHNCalendarEvent } from '../../../models/ohn-instances';
import { OhnApiService } from '../../../services/ohn-api.service';
import { Subject} from 'rxjs';
import { API_URL, DEBUG_MODE } from '../../../../environments/environment';
import { PopoverComponent } from '../../../components/basic/popover/popover.component';
import { CalendarEventViewPage } from '../../../secured/calendar-event-view/calendar-event-view.page';
import { BranchedContentRendererComponent } from '../../branched-content-renderer/branched-content-renderer.component';
import { AuthenticationService } from '../../../services/authentication.service';
import { OhnLanguageService } from '../../../services/ohn-language.service';

@Component({
  selector: 'app-common-single-patient-assessment',
  templateUrl: './common-single-patient-assessment.component.html',
  styleUrls: ['./common-single-patient-assessment.component.scss'],
})
export class CommonSinglePatientAssessmentComponent implements OnInit {

  @Input() el: OHNElement; 
  @Input() me: OHNUser;
  @Input() refresher : Subject<boolean>;

  currentScreenState : string = 'patientHome';

  loading : boolean = false;

  noEmail : boolean = false;

  constructor(
    private alertController: AlertController,
    private ohnApi: OhnApiService,
    private auth: AuthenticationService,
    public modalController: ModalController,
    public popoverController: PopoverController,
    private lS: OhnLanguageService,
  ) { }

  ngOnInit() {
  }

  startSurvey() {
    this.loading = true;
    this.scheduleEvent(this.generateEvent());
  }

  generateEvent(){
    let event = new OHNCalendarEvent({
      title : this.el.text,
      startTime : new Date(),
      completed : false,
      allDay: true,
      controller : 'survey',
      inner_element_slug : this.el.config.assessmentSlug,
      inner_element_state_id : '',
      report_element_slug : this.el.config.reportSlug,
      report_element_state_id : '',
      content : ''
    });
    return event;
  }

  scheduleEvent(event: any) {
    this.ohnApi.setElementStateSc('calendar_container', {value: event}, this.me.smart_contract).subscribe(newEvent => {
      this.openEventModal(new OHNCalendarEvent(newEvent));
      this.loading = false;
    });
  }

  async openEventModal(event: any) {
    const contentPage = await this.modalController.create({
      component: BranchedContentRendererComponent,
      componentProps: {
        parentElementSlug : event.inner_element_slug,
        user : this.me,
        event : event,
        hideCloseButton: true
      },
      cssClass: 'modal-fullscreen',
      backdropDismiss: false
    });

    contentPage.onDidDismiss().then((data) => {
      if (data.data.finished) {
        this.loadThankYouPage()
      }
    })

    return await contentPage.present();
  }

  loadThankYouPage() {
    this.loading = true;
    this.ohnApi.getMe().subscribe(user=>{
      this.me = <OHNUser>user;
      this.currentScreenState = 'patientThankYou';
      this.loading = false;
    })
  }

}
