import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { Observable, throwError , empty, BehaviorSubject} from 'rxjs';
import { AuthenticationService } from './authentication.service';
import { OhnLanguageService } from './ohn-language.service';
import { OhnService } from './ohn.service';
import { environment, API_URL, APP_SLUG, BASIC_HEADERS } from '../../environments/environment';
import { OHNElement, OHNUser, OHNUserRole, OHNExternalDevice } from '../models/ohn-instances';


const TOKEN_KEY = 'ohn-auth-token';

@Injectable({
  providedIn: 'root'
})

export class OhnApiService {

	httpOptions: any = {
		headers: new HttpHeaders(BASIC_HEADERS)
	}

	locale : string = 'en';

	_API_URL = localStorage.getItem('OHN_DEBUG_SERV_URL') ? localStorage.getItem('OHN_DEBUG_SERV_URL') : API_URL;

	tokenReceivedFromStorage : any =  new BehaviorSubject(false);

  constructor(
  	public ohnService: OhnService,
  	private http: HttpClient, 
  	private auth: AuthenticationService, 
  	private platform : Platform,
  	private lS: OhnLanguageService,
  ) {

  		this.platform.ready().then(() => {
	      this.getLocale();
	    });

	    this.auth.authenticationState.subscribe(state => {
        if (state) this.getToken();
      });

      this.auth.logoutEvent.subscribe(state => {
        if (state) this._API_URL = API_URL;
      });

      this.lS.languageChanger.subscribe(locale=>{
      	this.locale = locale;
      });
  }

  setApiURL(url : string){
  	this._API_URL = url;
  }

  logDataToServ(data: any): Observable<any>  {

		return this.http.post(this._API_URL+ '/healthkit/upload/' + APP_SLUG, data, this.httpOptions)
		.pipe(
			map(res => {
				return res;
			}),
			catchError(err => {
				alert('Logging Error : ' + JSON.stringify(err));
				this.errorHandler(err, 'Logging to serv ' + data, '');
				return empty();
			})
    )
	}

  getToken() {
    if (localStorage.getItem(TOKEN_KEY)) {
    	let headers: any = BASIC_HEADERS;
    	headers['Authorization'] = localStorage.getItem(TOKEN_KEY);
      this.httpOptions = {
				headers: new HttpHeaders(headers)
			}
			this.tokenReceivedFromStorage.next(true);
		}
	}

	setToken(token: string) {
		let headers: any = BASIC_HEADERS;
    headers['Authorization'] = localStorage.getItem(TOKEN_KEY);
		this.httpOptions = {
			headers: new HttpHeaders(headers)
		}
	}

	getLocale() {
		let locale = localStorage.getItem('ohn-locale');
    this.locale = locale ? locale : 'en';
	}

	authService(postInfo: any): Observable<any>  {

		postInfo['app_slug'] = APP_SLUG;

		return this.http.post(this._API_URL+ '/auth/register', postInfo, this.httpOptions)
		.pipe(
			map(res => {
				return res;
			})
    )
	}

	getApp() : Observable<any> {
    return this.http.get(this._API_URL+ '/' + APP_SLUG + '/app/'+ this.locale, this.httpOptions)
		.pipe(
			map(res => {
				return res;
			}),
			catchError(err => {
				this.errorHandler(err, 'Getting App', 'gettingApp');
				return empty();
			})
    )
	}

	getUserList(): Observable<any> {
    return this.http.get(this._API_URL+ '/' + APP_SLUG + '/user/list', this.httpOptions)
		.pipe(
			map(res => {
				return res;
			}),
			catchError(err => {
				this.errorHandler(err, 'Getting user list', 'gettingUserList');
				return empty();
			})
    )
  }

	getUserListByRole(role: string): Observable<any> {
    return this.http.get(API_URL+ '/' + APP_SLUG + '/user/list/' + role, this.httpOptions)
		.pipe(
			map(res => {
				return res;
			}),
			catchError(err => {
				this.errorHandler(err, 'Getting user list by role', 'gettingUserListByRole');
				return empty();
			})
    )
  }

	getUserListFiltered(query: string): Observable<any> {
		// projects[]=project_project_11628231374202&roles[]=patientspherex_coder&roles[]=patientspherex_patient
    return this.http.get(API_URL+ '/' + APP_SLUG + '/user/list?' + query, this.httpOptions)
		.pipe(
			map(res => {
				return res;
			}),
			catchError(err => {
				this.errorHandler(err, 'Getting user list filtered', 'gettingUserListFiltered');
				return empty();
			})
    )
  }

  getAvailableRoles(): Observable<any> {
    return this.http.get(this._API_URL+ '/' + APP_SLUG + '/role/list', this.httpOptions)
		.pipe(
			map(res => {
				return res;
			}),
			catchError(err => {
				this.errorHandler(err, 'Getting user role list', '');
				return empty();
			})
    )
  }

  getMe(): Observable<any> {
    return this.http.get(this._API_URL+ '/' + APP_SLUG + '/user', this.httpOptions)
		.pipe(
			map(res => {
				return res;
			}),
			catchError(err => {
				this.errorHandler(err, 'Getting Me', '');
				return empty();
			})
    )
  }

	getElement(elementSlug: string, depth: number): Observable<any>  {

		return this.http.get(this._API_URL+ '/' + APP_SLUG + '/' + elementSlug + '/'+ this.locale + '/' + depth, this.httpOptions)
		.pipe(
			map(res => {
				return res;
			}),
			catchError(err => {
				this.errorHandler(err, 'Getting element ' + elementSlug, '');
				return empty();
			})
    )
	}

	getElementCustomLocale(elementSlug: string, depth: number, locale: string): Observable<any>  {

		return this.http.get(this._API_URL+ '/' + APP_SLUG + '/' + elementSlug + '/'+ locale + '/' + depth, this.httpOptions)
		.pipe(
			map(res => {
				return res;
			}),
			catchError(err => {
				this.errorHandler(err, 'Getting element ' + elementSlug, '');
				return empty();
			})
    )
	}

	setElement(elementSlug: string, data: any): Observable<any>  {

		return this.http.put(this._API_URL+ '/' + APP_SLUG + '/' + elementSlug + '/'+ this.locale, data, this.httpOptions)
		.pipe(
			map(res => {
				return res;
			}),
			catchError(err => {
				this.errorHandler(err, 'Setting element ' + elementSlug, '');
				return empty();
			})
    )
	}

	setElementCustomLocale(elementSlug: string, data: any, locale: string): Observable<any>  {

		return this.http.put(this._API_URL+ '/' + APP_SLUG + '/' + elementSlug + '/'+ locale, data, this.httpOptions)
		.pipe(
			map(res => {
				return res;
			}),
			catchError(err => {
				this.errorHandler(err, 'Setting element ' + elementSlug, '');
				return empty();
			})
    )
	}

	getElementState(elementSlug: string): Observable<any>  {

		return this.http.get(this._API_URL+ '/' + APP_SLUG + '/' + elementSlug + '/state', this.httpOptions)
		.pipe(
			map(res => {
				return res;
			}),
			catchError(err => {
				this.errorHandler(err, 'Getting element state ' + elementSlug + ' for current user ', '');
				return empty();
			})
    )
	}

	getElementStateSc(elementSlug: string, smartContract: string): Observable<any>  {

		return this.http.get(this._API_URL+ '/' + APP_SLUG + '/' + elementSlug + '/state/smart_contract/' + smartContract, this.httpOptions)
		.pipe(
			map(res => {
				return res;
			}),
			catchError(err => {
				this.errorHandler(err, 'Getting element state ' + elementSlug + ' for smart contract ' + smartContract, '');
				return empty();
			})
    )
	}

	getElementStateRepeatableSc(elementSlug: string, smartContract: string): Observable<any>  {

		return this.http.get(this._API_URL+ '/' + APP_SLUG + '/' + elementSlug + '/state/repeating/smart_contract/' + smartContract, this.httpOptions)
		.pipe(
			map(res => {
				return res;
			}),
			catchError(err => {
				this.errorHandler(err, 'Getting repeating element state ' + elementSlug + ' for smart contract ' + smartContract, '');
				return empty();
			})
    )
	}

	setElementState(elementSlug: string, data: any): Observable<any>  {

		return this.http.put(this._API_URL+ '/' + APP_SLUG + '/' + elementSlug + '/state', data, this.httpOptions)
		.pipe(
			map(res => {
				return res;
			}),
			catchError(err => {
				this.errorHandler(err, 'Setting element state ' + elementSlug + ' for current user', '');
				return empty();
			})
    )
	}

	patchElementState(elementSlug: string, data: any): Observable<any>  {

		return this.http.patch(this._API_URL+ '/' + APP_SLUG + '/' + elementSlug + '/state', data, this.httpOptions)
		.pipe(
			map(res => {
				return res;
			}),
			catchError(err => {
				this.errorHandler(err, 'Patching element state ' + elementSlug + ' for current user', '');
				return empty();
			})
    )
	}

	patchElementStateSc(elementSlug: string, data: any, smartContract: string): Observable<any>  {
		data['smart_contract'] = smartContract;
		return this.http.patch(this._API_URL+ '/' + APP_SLUG + '/' + elementSlug + '/state', data, this.httpOptions)
		.pipe(
			map(res => {
				return res;
			}),
			catchError(err => {
				this.errorHandler(err, 'Patching element state ' + elementSlug + ' for current user', '');
				return empty();
			})
    )
	}

	setElementStateSc(elementSlug: string, data: any, smartContract: string): Observable<any>  {

		data['smart_contract'] = smartContract;

		return this.http.put(this._API_URL+ '/' + APP_SLUG + '/' + elementSlug + '/state', data, this.httpOptions)
		.pipe(
			map(res => {
				return res;
			}),
			catchError(err => {
				this.errorHandler(err, 'Setting element state ' + elementSlug + ' for smart contract ' + smartContract, '');
				return empty();
			})
    )
	}

	getElementStateById(elementSlug: string, smartContract: string, stateId : string): Observable<any>  {

		return this.http.get(this._API_URL+ '/' + APP_SLUG + '/' + elementSlug + '/state/smart_contract/' + smartContract + '/id/' + stateId, this.httpOptions)
		//return this.http.get(API_URL+ '/' + APP_SLUG + '/' + elementSlug + '/state/smart_contract/' + smartContract + '/' + stateId, this.httpOptions)
		.pipe(
			map(res => {
				return res;
			}),
			catchError(err => {
				this.errorHandler(err, 'Getting element state ' + elementSlug + ' for smart contract ' + smartContract, '');
				return empty();
			})
    )
	}

	getElementHistory(elementSlug: string, period: string, page: number): Observable<any>  {

		return this.http.get(this._API_URL+ '/' + APP_SLUG + '/' + elementSlug + '/history/period/' + period + '/' + page, this.httpOptions)
		.pipe(
			map(res => {
				return res;
			}),
			catchError(err => {
				this.errorHandler(err, 'Getting element history ' + elementSlug + ' for current user', '');
				return empty();
			})
    )
	}

	setElementHistory(elementSlug: string, data: any): Observable<any>  {

		return this.http.put(this._API_URL+ '/' + APP_SLUG + '/' + elementSlug + '/history', data, this.httpOptions)
		.pipe(
			map(res => {
				return res;
			}),
			catchError(err => {
				this.errorHandler(err, 'Setting element history ' + elementSlug, '');
				return empty();
			})
    )
	}

	getElementHistoryByDates(elementSlug: string, dates: string[]): Observable<any>  {

		return this.http.get(this._API_URL+ '/' + APP_SLUG + '/' + elementSlug + '/history/date/' + dates[0] + '/' + dates[1], this.httpOptions)
		.pipe(
			map(res => {
				return res;
			}),
			catchError(err => {
				this.errorHandler(err, 'Getting element history ' + elementSlug + ' for current user', '');
				return empty();
			})
    )
	}

	getElementHistoryByDatesSc(elementSlug: string, dates: string[], smartContract: string): Observable<any>  {

		return this.http.get(this._API_URL+ '/' + APP_SLUG + '/' + elementSlug + '/history/smart_contract/' + smartContract + '/date/' + dates[0] + '/' + dates[1], this.httpOptions)
		.pipe(
			map(res => {
				return res;
			}),
			catchError(err => {
				this.errorHandler(err, 'Getting element history ' + elementSlug + ' for current user', '');
				return empty();
			})
    )
	}


	getElementHistorySc(elementSlug: string, period: string, page: number, smartContract: string): Observable<any>  {

		return this.http.get(this._API_URL+ '/' + APP_SLUG + '/' + elementSlug + '/history/smart_contract/' + smartContract + '/period/' + period + '/' + page, this.httpOptions)
		.pipe(
			map(res => {
				return res;
			}),
			catchError(err => {
				this.errorHandler(err, 'Getting element history ' + elementSlug + ' for smart contract ' + smartContract, '');
				return empty();
			})
    )
	}

	getElementHistoryScCostil(elementSlug: string, period: string, page: number, smartContract: string): Observable<any>  {

		return this.http.get(this._API_URL+ '/ohnapi/' + APP_SLUG + '/' + elementSlug + '/history/smart_contract/' + smartContract + '/period/' + period + '/' + page, this.httpOptions)
		.pipe(
			map(res => {
				return res;
			}),
			catchError(err => {
				this.errorHandler(err, 'Getting element history ' + elementSlug + ' for smart contract ' + smartContract, '');
				return empty();
			})
    )
	}

	getElementHistoryByDatesScCostil(elementSlug: string, dates: string[], smartContract: string): Observable<any>  {

		return this.http.get(this._API_URL+ '/ohnapi/' + APP_SLUG + '/' + elementSlug + '/history/smart_contract/' + smartContract + '/date/' + dates[0] + '/' + dates[1], this.httpOptions)
		.pipe(
			map(res => {
				return res;
			}),
			catchError(err => {
				this.errorHandler(err, 'Getting element history ' + elementSlug + ' for current user', '');
				return empty();
			})
    )
	}

	deleteElementStateSc(elementSlug: string, data: any, smartContract: string): Observable<any>  {

		data['smart_contract'] = smartContract;

		let options : any = this.httpOptions;

		options['body'] = data;

		return this.http.delete(this._API_URL+ '/' + APP_SLUG + '/' + elementSlug + '/state', options)
		.pipe(
			map(res => {
				return res;
			}),
			catchError(err => {
				this.errorHandler(err, 'Deleting element state ' + elementSlug + ' for smart contract ' + smartContract, '');
				return empty();
			})
    )
	}

	addNewUser(user: OHNUser) : Observable<any>  {
    user.app_slug = APP_SLUG;
    return this.http.put(this._API_URL+ '/' + APP_SLUG + '/user', user, this.httpOptions)
    .pipe(
			map(res => {
				return res;
			}),
			catchError(err => {
				this.errorHandler(err, 'Creating user ' + user.email, 'addingNewUser');
				return empty();
			})
    )
  }

  grantUserRole(data: any) : Observable<any> {
  	return this.http.put(this._API_URL+ '/' + APP_SLUG + '/user/role', data, this.httpOptions)
    .pipe(
			map(res => {
				return res;
			}),
			catchError(err => {
				this.errorHandler(err, 'Granting role to user ' + data.username, '');
				return empty();
			})
    )
  }

  patchUser(user: OHNUser) : Observable<any>  {
    user.app_slug = APP_SLUG;
    return this.http.patch(this._API_URL+ '/' + APP_SLUG + '/user', user, this.httpOptions)
    .pipe(
			map(res => {
				return res;
			}),
			catchError(err => {
				this.errorHandler(err, 'Patching user account' + user.email, '');
				return empty();
			})
    )
  }

  deleteUser(smartContract: string): Observable<any>  {
		return this.http.delete(this._API_URL+ '/' + APP_SLUG + '/user/smart_contract/' + smartContract, this.httpOptions)
		.pipe(
			map(res => {
				return res;
			}),
			catchError(err => {
				this.errorHandler(err, 'Deleting user with smart contract ' + smartContract, '');
				return empty();
			})
    )
	}

	getFitbitPairedDevices() : Observable<any> {
		return this.http.get(this._API_URL+ '/device', this.httpOptions)
		.pipe(
			map(res => {
				return res;
			}),
			catchError(err => {
				this.errorHandler(err, 'Getting Paired Devices', '');
				return empty();
			})
		)
	}

	syncFitbitData() : Observable<any> {
		return this.http.get(this._API_URL+ '/fitbit', this.httpOptions)
		.pipe(
			map(res => {
				return res;
			}),
			catchError(err => {
				this.errorHandler(err, 'Syncing Fitbit', '');
				return empty();
			})
		)
	}

	patchDevice(device : any) : Observable<any> {
		return this.http.patch(this._API_URL+ '/device', device, this.httpOptions)
		.pipe(
			map(res => {
				return res;
			}),
			catchError(err => {
				this.errorHandler(err, 'Patching Devices', '');
				return empty();
			})
		)
	}

	getFitbitSyncURL(smartContract: string) : Observable<any> {
		return this.http.get(this._API_URL+ '/fitbit/' + smartContract, this.httpOptions)
		.pipe(
			map(res => {
				return res;
			}),
			catchError(err => {
				this.errorHandler(err, 'Getting Sync URL', '');
				return empty();
			})
		)
	}

	forgetFitbitDevice(uuid: string) : Observable<any> {
				
		//let options : any = this.httpOptions;

		//options['body'] = {device_uuid: uuid};

		return this.http.delete(this._API_URL+ '/' + APP_SLUG +'/user_device/' + uuid, this.httpOptions)
		.pipe(
			map(res => {
				return res;
			}),
			catchError(err => {
				this.errorHandler(err, 'Forgetting FitBit device UUID: ' + uuid, '');
				return empty();
			})
    )
	}

  getFitbitPairingCode() : Observable<any> {
    return this.http.get(this._API_URL+ '/pair_device', this.httpOptions)
		.pipe(
			map(res => {
				return res;
			}),
			catchError(err => {
				this.errorHandler(err, 'Getting Pairing Token', '');
				return empty();
			})
    )
	}

	setFitbitPairingCode(device_code: string) : Observable<any> {
		return this.http.post(this._API_URL + '/device', {code: device_code}, this.httpOptions)
		.pipe(
			map(res => {
				return res;
			})
		)
	}

	getPatientReport(elementSlug : string, smartContract: string) : Observable<any> {
    return this.http.get(this._API_URL+ '/' + APP_SLUG + '/user/share_report/' + elementSlug + '/raw/smart_contract/' + smartContract, this.httpOptions)
		.pipe(
			map(res => {
				return res;
			}),
			catchError(err => {
				this.errorHandler(err, 'Getting patient report', '');
				return empty();
			})
    )
	}

	searchElementQuery(elementSlug: string, data: any): Observable<any>  {

		return this.http.put(this._API_URL+ '/' + APP_SLUG + '/' + elementSlug + '/query', data, this.httpOptions)
		.pipe(
			map(res => {
				return res;
			}),
			catchError(err => {
				this.errorHandler(err, 'Searching query ' + elementSlug + ' for current user', '');
				return empty();
			})
    )
	}

	getAvailableExternalDevices() : Observable<any> {
    return this.http.get(this._API_URL+ '/supported_devices', this.httpOptions)
		.pipe(
			map(res => {
				return res;
			}),
			catchError(err => {
				this.errorHandler(err, 'Getting Available Devices', '');
				return empty();
			})
    )
	}

	getUserDevices(smartContract: string) : Observable<any> {
    return this.http.get(this._API_URL+ '/' + APP_SLUG + '/user_device/' + smartContract, this.httpOptions)
		.pipe(
			map(res => {
				return res;
			}),
			catchError(err => {
				this.errorHandler(err, 'Getting User Devices', '');
				return empty();
			})
    )
	}

	addUserDevice(device: OHNExternalDevice, smartContract: string) : Observable<any> {
    return this.http.put(this._API_URL+  '/' + APP_SLUG + '/user_device/' + smartContract, device, this.httpOptions)
		.pipe(
			map(res => {
				return res;
			}),
			catchError(err => {
				this.errorHandler(err, 'Adding User Device', '');
				return empty();
			})
    )
	}

	deleteUserDevice(deviceUUID: string, smartContract: string): Observable<any>  {
		return this.http.delete(this._API_URL+ '/' + APP_SLUG +'/user_device/' + smartContract + '/' + deviceUUID, this.httpOptions)
		.pipe(
			map(res => {
				return res;
			}),
			catchError(err => {
				this.errorHandler(err, 'Deleting user device for smart contract ' + smartContract, '');
				return empty();
			})
    )
	}

	appSync(): Observable<any>  {
		return this.http.get(this._API_URL+ '/ohnapi/' + APP_SLUG + '/sync', this.httpOptions)
		.pipe(
			map(res => {
				return res;
			}),
			catchError(err => {
				this.errorHandler(err, 'syncing', '');
				return empty();
			})
    )
	}

	syncUserDevices(smartContract: string): Observable<any>  {
		return this.http.get(this._API_URL+ '/' + APP_SLUG + '/sync/' + smartContract, this.httpOptions)
		.pipe(
			map(res => {
				return res;
			}),
			catchError(err => {
				this.errorHandler(err, 'syncing user devices', '');
				return empty();
			})
    )
	}

	generatePDF(title: string, username: string, data: any): Observable<any>  {
		const headers = {
			'Content-Type':  'application/json'
		}
		let httpOptions: any = {
			headers: new HttpHeaders(headers),
			responseType: 'blob'
		}
		return this.http.put(this._API_URL+ '/webhooks/pdfrender', 
			{
				title : title,
				username : username,
				html : data
			}, httpOptions)
		.pipe(
			map(res => {
				return res;
			}),
			catchError(err => {
				this.errorHandler(err, 'Generating PDF ', '');
				return empty();
			})
    )
	}

	getUserHistoryReport(elementSlug : string, query: string) : Observable<any> {
		return this.http.get(this._API_URL+ '/' + APP_SLUG + '/user_history_report/' + elementSlug + '?' + query, this.httpOptions)
		.pipe(
			map(res => {
				return res;
			}),
			catchError(err => {
				this.errorHandler(err, 'Getting user history report ' + elementSlug, '');
				return empty();
			})
    )
	}

	getUserByEmail(email : string) : Observable<any> {
		return this.http.get(this._API_URL+ '/' + APP_SLUG + '/user/username/' + email, this.httpOptions)
		.pipe(
			map(res => {
				return res;
			})
    )
	}

	sendWorkoutHistoryReport() : Observable<any> {
		return this.http.post(this._API_URL+ '/' + APP_SLUG + '/workout_history_report', '', this.httpOptions)
		.pipe(
			map(res => {
				return res;
			}),
			catchError(err => {
				this.errorHandler(err, 'Sending workout history report', '');
				return empty();
			})
    )
	}

	errorHandler(error: any, context: string, contextEvent: string) {
		console.log(context);
		switch (error.status) {
		  case 401:
		    this.ohnService.stopLoading().then(()=>{
		   		this.auth.logout();
		   	});
		    break;
		  case 403:
		   	this.ohnService.stopLoading().then(()=>{
		   		this.auth.logout();
		   	});
		    break;
		  case 500:
		      this.ohnService.stopLoading();
		      this.ohnService.showAlert('Server Error', 'Something bad happened on the server. Please ping support');
		    break;
		  case 502:
		      this.ohnService.stopLoading();
		    break;
		  case 405:
		      this.ohnService.stopLoading();
		      switch (contextEvent) {
		      	case "addingNewUser":
		      		this.ohnService.showAlert('User Exists', 'User already exists. Choose different username.');
		      		break;
		      }
		    break;
		  case 406:
		      this.ohnService.stopLoading().then(()=>{
			  		this.ohnService.showAlert('Update Error', 'This phone number is already in use.');
			  	});
		    break;
		  
		  default:
		  	this.ohnService.stopLoading().then(()=>{
		  		console.log(error);
		  		this.ohnService.showAlert('Connection Error', 'Please check internet connection.');
		  	});
		    break;
		}
	}
}